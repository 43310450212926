<template>
    <div class="default-table">
        <el-table :data="dataList"
                  highlight-current-row border
                  @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column type="index" label="序号" width="65" align="center"></el-table-column>
          <el-table-column label="组别" prop="group_name" align="center" width="160"></el-table-column>
          <el-table-column label="红人" prop="artist_name" align="center" width="160"></el-table-column>
          <el-table-column label="日期" prop="date" align="center" width="160"></el-table-column>
          <el-table-column label="消息类型" prop="type" align="center" width="120">
            <template slot-scope="{row}">
              <el-tag v-if="row.type == 1">脚本</el-tag>
              <el-tag v-else-if="row.type ==2" type="success">拍摄</el-tag>
              <el-tag v-else-if="row.type ==3" type="info">剪辑</el-tag>
              <el-tag v-else-if="row.type ==4" type="warning">发布内容</el-tag>
              <el-tag v-else type="danger">会议</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="主题内容" prop="content" align="center"></el-table-column>
          <el-table-column label="创建人" prop="creator_name" align="center" width="120"></el-table-column>
          <el-table-column label="是否完成" prop="is_finish" align="center" width="120">
            <template slot-scope="{row}">
              <span v-if="row.is_finish == 1">未完成</span>
              <span v-else>已完成</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="created_at" align="center" width="160"></el-table-column>
          <el-table-column label="最后修改时间" prop="updated_at" align="center" width="160"></el-table-column>
          <el-table-column label="操作" align="center" width="120">
            <template slot-scope="{row}">
              <el-button type="text" icon="el-icon-edit" @click="showDetail(row)"
                         v-if="userPermissions.indexOf('work-schedule-update') > -1">修改
              </el-button>
              <el-button type="text" icon="el-icon-delete" @click="handleDelete(row)"
                         v-if="userPermissions.indexOf('work-schedule-delete') > -1">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      <pagination :hidden="total===0"
                  :background="true"
                  :total.sync="total"
                  :page.sync="pageData.current_page"
                  :limit.sync="pageData.page_size"
                  @pagination="getList"
      />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TaskTable',
  props: {

    searchCondition: {
      type: Object,
      default() {
        return {}
      }
    },
    propCondition: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      ids: [],
      pageData: { page_size: 15, current_page: 1 },
      total: 0,
      dataList: []
    }
  },
  watch: {
    propCondition: {
      immediate: true,
      handler() {
        this.getList()
      }
    }
  },
  methods: {
    async getList() {
      this.loading = true
      this.dataList = []
      let searchCondition = this.propCondition
      Object.assign(searchCondition, this.pageData)
      this.propData = searchCondition

      let { list, page_info } = await this.$api.getTaskScheduleList(searchCondition)
      this.dataList = list || []
      this.pageData.current_page = page_info.current_page || 1
      this.pageData.page_size = page_info.page_size || 1
      this.total = page_info.total
      this.loading = false
    },
    showDetail(data) {
      this.$emit('handleCmd', 'detail', data)
    },
    handleDelete(data) {
      this.$emit('handleCmd', 'delete', data)
    },
    handleSelectionChange(val) {
      this.ids = val.map(i => {
        return i.id
      })
      this.$emit('change', this.ids)
    }
  },
}
</script>

<style scoped>

</style>
