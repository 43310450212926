<template>
    <div>
      <el-dialog v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose"
                 :title="isAdd?'添加排期':'修改排期'"
                 width="900px">
        <el-form ref="elForm" size="medium" :model="formData" :rules="rules" label-width="100px">
          <el-form-item label="组别" prop="dept">
            <el-cascader
                v-model="formData.dept"
                :props="deptProps"
                :options="deptList"
                ref="cascader"
                clearable
                @change="handleQuery"></el-cascader>
          </el-form-item>
          <el-form-item label="红人" prop="artist_id">
            <span v-if="nickShow">{{ taskFormData.artist_name }}</span>
            <el-select v-else ref="artist" v-model="formData.artist_id" placeholder="请选择">
              <el-option
                  v-for="item in groupArtists"
                  :key="item.id"
                  :label="item.nickname"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排期日期" prop="date">
            <el-date-picker
                v-model="formData.date"
                align="right"
                range-separator="~"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="消息类型" prop="type">
            <el-select v-model="formData.type" placeholder="消息类型">
              <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="主题内容" prop="content">
            <el-input
                type="textarea"
                :rows="2"
                show-word-limit
                maxlength="500"
                placeholder="请输入内容"
                v-model="formData.content">
            </el-input>
          </el-form-item>
          <el-form-item label="是否完成" prop="is_finish">
            <el-radio v-model="formData.is_finish" :label='1'>未完成</el-radio>
            <el-radio v-model="formData.is_finish" :label='2'>已完成</el-radio>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-row>
            <el-col :span="24" style="text-align: center">
              <el-button type="primary" size="large" @click="addTaskSchedule" v-if="canSave">
                {{ isAdd ? '添加' : '修改' }}
              </el-button>
              <el-button @click="close" size="large">取消</el-button>
            </el-col>
          </el-row>
        </div>
      </el-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CreateEdit',
  components: {},
  props: {
    taskFormData: {
      type: Object,
      default() {
        return {
          type: null,
          date: null,
          content: null,
          is_finish: null,
          dept: [],
          artist_id: ''

        }
      }
    },
    deptList: [],
    deptProps: {}
  },
  computed: {
    ...mapGetters(['userPermissions']),
    canSave() {
      return this.formData.id ? this.userPermissions.indexOf('work-schedule-update') > -1 : this.userPermissions.indexOf('work-schedule-create') > -1
    }
  },
  watch: {
    taskFormData() {
      this.isAdd = this.taskFormData && this.taskFormData.id === undefined

    }
  },
  data() {
    return {
      isAdd: true,
      typeOptions: [
        { value: 1, label: '脚本' },
        { value: 2, label: '拍摄' },
        { value: 3, label: '剪辑' },
        { value: 4, label: '发布内容' },
        { value: 5, label: '会议' }
      ],
      formData: {
        type: null,
        date: null,
        content: null,
        is_finish: null,
        artist_id: null
      },
      nickShow: false,
      rules: {
        dept: { required: true, message: '请选择组别', trigger: 'change' },
        artist_id: { required: true, message: '请选择红人', trigger: 'change' },
        type: { required: true, message: '请选择类型', trigger: 'change' },
        date: { required: true, message: '请选择日期', trigger: 'change' },
        content: { required: true, message: '请输入主题内容', trigger: 'change' },
        is_finish: { required: true, message: '请选择是否完成', trigger: 'change' }
      },
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      },
      groupArtists: [],
      dept: []
    }
  },
  methods: {

    async getGroupArtists() {
      let data = { group_id: this.formData.group_id, sign_status: 'Y' }
      let { list } = await this.$api.getTaskdeptArtists(data)
      this.groupArtists = list
    },


    handleQuery() {
      let nodesInfo = this.$refs['cascader'].getCheckedNodes()
      this.formData.group_name = nodesInfo[0].label
      this.formData.group_id = nodesInfo[0].value
      this.formData.artist_id = ''
      this.formData.nickname = null
      this.nickShow = false
      this.getGroupArtists()

    },

    onOpen() {
      this.nickShow = true
      if (this.isAdd) {
        this.formData = {
          type: null,
          date: null,
          content: null,
          is_finish: null,
          dept: [],
          artist_id: ''
        }
        this.formData = this.taskFormData
        this.nickShow = false
        //修改
      } else {
        this.formData = this.taskFormData
        this.formData.dept = this.taskFormData.group_id
      }
      this.getGroupArtists()
    },
    addTaskSchedule() {
      this.$refs.elForm.validate(async (valid) => {
        if (!valid) {
          return
        } else {
          delete this.formData.dept
          //修改工作排期
          if (!this.isAdd) {
            let nodesInfo = this.$refs['cascader'].getCheckedNodes()
            this.formData.group_name = nodesInfo[0].label
            this.formData.group_id = nodesInfo[0].value
            const res = await this.$api.updateTaskSchedule(this.formData)
            if (!res) {
              this.$message.success('失败,请联系管理员')
            }
          } else {
            //添加工作排期
            // this.handleQuery()
            // this.formData.artist_id = this.taskFormData.artist_id
            let nodesInfo = this.$refs['cascader'].getCheckedNodes()
            this.formData.group_name = nodesInfo[0].label
            this.formData.group_id = nodesInfo[0].value
            const res = await this.$api.addTaskSchedule(this.formData)
            if (!res) {
              this.$message.success('失败,请联系管理员')
            }
          }
          this.close()

        }
        this.close()
      })
    },
    onClose() {
      this.$refs['elForm'].resetFields()
    },
    close() {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped>
</style>
