<template>
  <div>
    <div class="el-calendar__body">
      <table cellspacing="0" cellpadding="0" class="el-calendar-table">
        <tbody>
        <tr class="el-calendar-table__row" v-for="(row, k) in calendarData" :key="k">
          <td :class="`platform ${row.show_date ? 'week' : ''}`">
            <span :style="{ color: row.platform?.color_val }">{{ row.platform?.label || '' }}</span>
          </td>
          <td :class="`${item.class_name} ${row.show_date ? 'week' : ''}`" v-for="(item, j)  in row.weekDays" :key="j"
              :style="`${extractField(row, item, 'cell_style')}`">
            <div v-if="row.show_date">

              <div :class="item.fullDate && ['周六', '周日'].includes(switchWeek(item.week)) ? 'sun' : 'weekDay'">
                <span>{{ switchWeek(item.week) }}</span>
              </div>
              <div :class="item.fullDate ? 'calendar-panel' : 'greyDate'">

                <div class="calendar-header">
                  <span v-if="item.fullDate" :class="['dates', todayDate === item.fullDate ? 'today' : '']">
                      {{ $utils.parseTime(item.fullDate, '{y}/{m}/{d}') }}
                    </span>
                  <span v-else class="dates">
                      {{ greyDates(item) }}
                    </span>
                  <!--                  <div v-else>-->
                  <!--                    /-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
            <div class="el-calendar-day" v-else
                 @click.stop="handleAdd(item,row)">
              <div class="calendar-body">
                <div v-if="item.fullDate">
                  <ul>
                    <li v-for="(j,k) in extractField(row, item)" :key="j.id" style="display: block"
                        class="liTex" @click.stop="handleEdit(j)" :style="{ color: row.platform?.color_val }">
                                          <span style="font-size: 16px;"
                                                :style="j.is_finish===2?{'text-decoration':'line-through'}:''">
                                           {{ k + 1 }}：{{ j.content }}
                                          </span>
                    </li>
                  </ul>
                </div>
                <div v-else>/</div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>


    <!--    <create-edit :visible.sync="open" @close="handleQuery" :deptList="deptList" :task-form-data="taskFormData" />-->

  </div>
</template>

<script>
import CalendarPanel from '@/pages/lb/kol/CalendarPanel.vue'
import CreateEdit from '../CreateEdit'
import { mapGetters } from 'vuex'

export default {
  name: 'ScheduleCalendar',
  components: { CalendarPanel, CreateEdit },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {}
      }
    },
    propCondition: {
      type: Object,
      default() {
        return {}
      }
    }

  },
  data() {
    return {
      defaultFormData: {},
      taskFormData: {},
      todayDate: null,
      menuData: {
        visible: false,
        top: 0,
        left: 0
      },
      currentItem: {},
      currentRow: {},
      platform: [
        { label: '脚本', type: 1, color_val: '#b5656b' },
        { label: '拍摄', type: 2, color_val: '#a992e7' },
        { label: '剪辑', type: 3, color_val: '#74b3df' },
        { label: '发布内容', type: 4, color_val: '#000000' },
        { label: '会议', type: 5, color_val: '#c89e87' }
      ],
      pageData: {
        page_size: 99,
        current_page: 1
      },
      deptList: [],
      deptProps: {
        label: 'name',
        value: 'dpt_id',
        children: 'groups'
      },
      dataList: []
    }
  },
  watch: {
    propCondition: {
      immediate: true,
      handler() {
        this.dataList = []
        this.pageData.current_page = 1
        this.getList()
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    currentId() {
      return this.currentRow ? this.currentRow.id : null
    },
    calendarData() {
      let dataList = []
      if (this.searchCondition.date) {
        //根据当前月份的第一天，计算出当月月份日历的数据
        const currentMonthFirstDay = `${this.searchCondition.date}-01`

        const days = this.$utils.calcCalendarDates(currentMonthFirstDay)
        let daysArr = this.$utils.array_chunk(days, 7)
        for (let j in daysArr) {
          // eslint-disable-next-line
          let weekDays = daysArr[j]
          // eslint-disable-next-line no-unused-vars
          weekDays.forEach((item) => {
            // console.log(item)
            item.data = item.fullDate ? this.dailyData[this.$utils.parseTime(item.fullDate, '{y}-{m}-{d}')] : []
            // item.show_all = true
          })
          dataList.push({ hidden_row: false, show_date: true, weekDays: weekDays, week: j })
          let platforms = this.platform //红人拥有的平台（已排序）
          let h = 0
          for (let i in platforms) {
            h++
            // eslint-disable-next-line
            let platform = platforms[i]
            let row = { hidden_row: false, show_date: h === 0, platform: platform, weekDays: weekDays, week: j }
            // let k = `${row.platform.code}_${row.week}`
            dataList.push(row)
          }
        }
      }
      // console.log(dataList[dataList.length - 1])
      return dataList
    },

    //本月的数据转为对象，对象的键为日期， date=>[data1,data2]
    dailyData() {
      let dataMap = {}
      this.dataList.forEach((item) => {
        const date = this.$utils.parseTime(item.date, '{y}-{m}-{d}')
        if (!dataMap[date]) dataMap[date] = []
        dataMap[date].push(item)
      })
      return dataMap
    }
  },
  created() {
    this.todayDate = this.getLastDays(0)
  },
  methods: {
    async getDept() {
      let dep = await this.$api.getTaskdept({ type: 'work_schedule_scope' })
      this.deptList = dep.list
    },
    async handleQuery() {
      this.$emit('close', true)
      this.taskFormData = {}
    },
    handleAdd(data, row) {
      if (this.userPermissions.indexOf('work-schedule-create') > -1) {
        if (data.fullDate) {
          this.taskFormData = {
            type: row.platform.type,
            is_finish: 1,
            content: null,
            dept: this.dept,
            date:data.fullDate
          }
          this.$emit('openDiag', this.taskFormData)
        }
      }
    },
    handleEdit(data) {
      if (this.userPermissions.indexOf('work-schedule-update') > -1) {
        this.taskFormData = data
        this.$emit('openDiag', this.taskFormData)
      }
    },
    async getList() {
      let searchCondition = {
        ...this.propCondition,
        ...this.pageData
      }
      // Object.assign(this.searchCondition, this.pageData)
      let { list, page_info } = await this.$api.getTaskScheduleList(searchCondition)
      list.forEach((item) => {
        this.dataList.push(item)
      })
      this.pageData = page_info
      if ((page_info.page_size * page_info.current_page) < page_info.total) {
        this.pageData.current_page++
        await this.getList()
      }
    },

    extractField(row, date) {
      let j = String(date.fullDate).split('-')
      let newDate = j[0] + '-' + String(j[1]) + '-' + String(Number(j[2]) < 10 ? '0' + j[2] : j[2])
      if (this.dataList) {
        let returnData = []
        returnData = this.dataList.map((item) => {
          return item.date === newDate && item?.type === row.platform?.type ? item : undefined
        }).filter(Boolean)
        return returnData
      }

    },
    switchWeek(data) {
      let d = ''
      switch (data) {
        case 1:
          d = '周一'
          break
        case 2:
          d = '周二'
          break
        case 3:
          d = '周三'
          break
        case 4:
          d = '周四'
          break
        case 5:
          d = '周五'
          break
        case 6:
          d = '周六'
          break
        case 0:
          d = '周日'
          break
      }
      return d
    },

    greyDates(item) {
      let splitDate = this.searchCondition.date.split('-')
      let year = splitDate[0]
      let month = Number(splitDate[1])
      if (month === 1) {
        if(item.class_name==='prev'){
          month=12
          year=year-1
        }else {
          month = 1
        }
      } else if (month === 12) {
        if(item.class_name==='prev'){
          month=month-1
        }else {
          month = 1
          year = Number(year) + 1
        }

      } else
        month = item.class_name === 'prev' ? month - 1 : month + 1
      month = month < 10 ? '0' + month : month
      return `${year}/${month}/${item.num}`
    },
    getLastDays(days) {
      //几天前的时间
      let day1 = new Date()
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000 * days)
      return day1.getFullYear() + '-' + (day1.getMonth() + 1) + '-' + day1.getDate()
    }
  },
  mounted() {
    this.getDept()
    // this.calendarData
  }
}
</script>

<style scoped lang="scss">
.sun {
  display: inline-block;
  width: calc(100% - 4px);
  //width: 120px;
  padding: 2px;
  border-right: 1px solid #ebeef5;
  background-color: #683499;
  color: #ffffff;
  text-align: center;
}

.dates {
  vertical-align: middle;
  /*font-weight: 600;*/
  line-height: 30px;
  height: 30px;
  text-align: center;
}

.greyWeek, .weekDay {
  display: inline-block;
  width: calc(100% - 4px);
  //width: 120px;
  padding: 2px;
  border-right: 1px solid #ebeef5;
  background-color: #606266;
  color: #ffffff;
  text-align: center;
}

.weekDay {
  background-color: black;
  border-bottom: 1px solid #606266;
}

.greyDate {
  //background-color: #C0C4CC;
  text-align: center;
  color: black;

  > .calendar-header {
    background-color: #898989;
    text-align: center;
    color: #f2f6fc !important;
  }

  .calendar-body {
    min-height: 130px;
    line-height: 130px;
    background-color: #d8d8d8;
    color: #f2f6fc !important;
  }
}

.calendar-panel {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  /*padding: 0;*/
  .calendar-header {
    background-color: #0c0c0c !important;
    text-align: center;
    color: #fff !important;
  }

  .calendar-body {
    /*border: #ff3176 1px dashed;*/
    background-color: #fff !important;
    color: #0c0c0c !important;
    width: 100%;
    /*border: 1px solid #001aff;*/
    margin: auto;
    display: flex;
    flex-direction: column;
    //height: 130px;
    min-height: 130px;
    overflow-y: scroll;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    /*z-index: 9;*/
    /*border: #ff3176 1px dashed;*/
  }

  .calendar-footer {
    /*background-color: #0C0C0C !important;*/
    /*color: #0C0C0C !important;*/
    line-height: 1;
    height: 22px;
    /*z-index: 10;*/
  }
}

.show-more {
  position: absolute;
  bottom: 0;
  width: 100%;
  cursor: pointer;
  padding: 1px;
  /*background-color: #0C0C0C !important;*/
  /*border: #ff3176 1px dashed;*/
  /*background-color: #ff3176;*/
  opacity: 0.7;
}

.platform {
  text-align: center;
  line-height: 100%;
  vertical-align: middle;
  cursor: pointer;
}

.week {
  /*border-top: 1px solid #DCDFE6;*/
  /*border-bottom: 1px solid #434549;*/
  margin-top: 10px;
  /*background-color: rgba(223, 224, 226, 0.7);*/
  /*background-color: rgba(220, 223, 230, 0.7);*/
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}

.el-calendar-day {
  min-height: 120px;
  height: 100%;
}

.liTex {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
