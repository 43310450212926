<template>
  <div class='main-page-content'>
    <el-row>
      <el-col :span='24'>
        <el-form :model='searchCondition' inline>
          <el-form-item label=''>
            <el-date-picker
              v-model='searchCondition.date'
              type='month'
              value-format='yyyy-MM'
              format='yyyy年MM月'
              @change='handleQuery'
              placeholder='选择月份' style='width: 140px'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-input v-model='searchCondition.content' placeholder='输入关键字' clearable
                      @change='handleQuery'></el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model='searchCondition.type' placeholder='消息类型' clearable
                       @change='handleQuery'>
              <el-option
                v-for='item in options'
                :key='item.value'
                :label='item.label'
                :value='item.value'>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-cascader
              :props='deptProps'
              v-model='searchCondition.dept'
              :options='deptList'
              clearable
              @change="handleQuery('dept')"></el-cascader>
          </el-form-item>
          <el-form-item label='红人' prop='artist_id'>
            <el-select ref='artist' @change='handleQuery' v-model='searchCondition.artist_id'
                       filterable clearable placeholder='请选择'>
              <el-option
                v-for='item in groupArtists'
                :key='item.id'
                :label='item.nickname'
                :value='item.id'>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click='handleQuery' icon='el-icon-search'>查询</el-button>
          </el-form-item>
          <el-form-item style='float: right;margin-top: -20px'>
            <ImageShare :share-content-id='`shareContent`' :btn-title='`分享排期`' :dialog-title='`排期分享图`' />
            <export-link ref='refExportLink' :can-export="userPermissions.indexOf('work-schedule-export') !== -1"
                         @export='exportData' />
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span='12'>
        <el-button type='primary' @click='handleAdd' icon='el-icon-plus'
                   v-if="userPermissions.indexOf('work-schedule-create')>-1">
          添加排期
        </el-button>
        <el-button :disabled='ids.length===0' icon='el-icon-delete' type='danger' @click='selectDel'
                   v-if="userPermissions.indexOf('work-schedule-delete')>-1">
          批量删除
        </el-button>
      </el-col>
      <el-col :span='12'>
        <el-radio-group v-model='mod' @change='locationMod' style='float: right'>
          <el-radio-button label='Calendar'>日历展示</el-radio-button>
          <el-radio-button label='Table'>列表展示</el-radio-button>
        </el-radio-group>
      </el-col>
    </el-row>
    <br>

    <div>
      <!--      日历展示-->
      <TaskCalender v-if="mod==='Calendar'&&loading"
                    id='shareContent' :propCondition='propCondition' @openDiag='openDiag' @close='handleQuery'
                    ref='calendar' :search-condition='searchCondition' />
      <TaskTable :dataList='dataList' :search-condition='searchCondition' v-if="mod==='Table'&&loading"
                 ref='table' :propCondition='propCondition ' @handleCmd='handleCmd' @change='change' />


    </div>
    <!-- 添加工作排期 -->
    <create-edit :visible.sync='open' @close='handleQuery' :groupArtists='groupArtists' :deptProps='deptProps'
                 :deptList='deptList' :task-form-data='taskFormData' />
  </div>
</template>

<script>
import TaskCalender from './work_manage/Index.vue'
import TaskTable from './TaskTable'
import CreateEdit from './CreateEdit'
import { mapGetters } from 'vuex'
import ExportLink from '@/components/export/ExportLink'

export default {
  name: 'taskSchedule',
  components: { TaskCalender, TaskTable, CreateEdit, ExportLink },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      mod: '',
      searchCondition: { content: null, type: null, date: null, dept: null },
      propCondition: {},
      open: false,
      loading: false,
      taskFormData: {
        type: null,
        date: null,
        content: null,
        is_finish: 1
      },
      dataList: [],
      options: [
        { value: '1', label: '脚本' },
        { value: '2', label: '拍摄' },
        { value: '3', label: '剪辑' },
        { value: '4', label: '发布内容' },
        { value: '5', label: '会议' }
      ],
      delIds: {},
      ids: [],
      deptList: [],
      deptProps: {
        label: 'name',
        value: 'dpt_id',
        children: 'groups'
      },
      groupArtists: []
    }
  },
  methods: {
    openDiag(val) {
      this.taskFormData = {
        ...val,
        dept: this.searchCondition.dept,
        artist_id: this.searchCondition.artist_id
      }
      this.open = true
    },
    locationMod() {
      localStorage.setItem('taskMode', this.mod)
    },
    async getGroupArtists() {
      let data = { group_id: this.searchCondition.dept[1], sign_status: 'Y' }
      let { list } = await this.$api.getTaskdeptArtists(data)
      this.groupArtists = list || []
      //增加逻辑判断，避免JS报错，导致页面渲染失败
      if (list && list.length > 0)
        this.searchCondition.artist_id = list[0].id
    },

    defaultTime() {
      const end = new Date()
      //年份选择：默认当月
      this.searchCondition.date = end.getFullYear() + '-' + (Number(end.getMonth() + 1) < 10 ? '0' + Number(end.getMonth() + 1) : end.getMonth() + 1)
    },
    async handleQuery(type) {
      if (type === 'dept') {
        this.searchCondition.artist_id = null
        await this.getGroupArtists()
      }
      this.propCondition = this.handleSearchCondition()
    },
    handleCmd(cmd, row) {
      switch (cmd) {
        case 'detail':
          this.showDetail(row)
          break
        case 'delete':
          this.handleDelete(row)
          break
      }
    },
    handleAdd() {
      this.taskFormData = {
        type: null,
        date: null,
        content: null,
        is_finish: 1,
        dept: this.searchCondition.dept,
        artist_id: this.searchCondition.artist_id
      }
      this.open = true
    },
    showDetail(row) {
      this.taskFormData = row || null
      this.open = true
    },
    change(data) {
      this.ids = data
      this.delIds.ids = this.ids
    },
    //批量删除
    selectDel() {
      this.$confirm('确定批量删除排期?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let data = await this.$api.deleteTaskSchedule(this.delIds)
        if (data == 1) {
          this.$message.success('删除成功')
        }
        // await this.getList()
        await this.$refs.table.getList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    //删除
    handleDelete(row) {
      this.ids.push(row.id)
      this.delIds.ids = this.ids
      this.$confirm('确定删除该排期?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let data = await this.$api.deleteTaskSchedule(this.delIds)
        if (data == 1) {
          this.$message.success('删除成功')
        }
        this.getList()

        // await this.getList()
        this.ids = []
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
        this.ids = []
      })
    },
    getList() {
      switch (this.mod) {
        case 'Table':
          this.$refs.table.getList()
          break
        case 'Calendar':
          this.$refs.calendar.getList()
          break
      }
    },
    async getDept() {
      let dep = await this.$api.getTaskdept({ type: 'work_schedule_scope' })
      this.deptList = dep.list
      let arr = []
      arr.push(dep.list[0].dpt_id, dep.list[0].groups[0].dpt_id)
      this.searchCondition.dept = arr
      await this.getGroupArtists()
      this.propCondition = this.handleSearchCondition()
      this.loading = true
      // await this.getList()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.date) {
        condition.date = this.searchCondition.date
      }
      if (this.searchCondition.content) {
        condition.content = this.searchCondition.content
      }
      if (this.searchCondition.type) {
        condition.type = this.searchCondition.type
      }
      if (this.searchCondition.dept) {
        condition.group_id = this.searchCondition.dept[1]
      }
      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      return condition
    },

    //导出表格
    async exportData() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await this.$api.exportTaskSchedule(searchCondition)
        let name = `工作排期`//${this.searchCondition.year}年${this.searchCondition.month}月-${this.searchCondition.nickname}
        if (searchCondition.date) {
         let dateArr= searchCondition.date.split('-')
          name+=`-${dateArr[0]}年${dateArr[1]}月`
        }

        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    }
  },
  created() {
    this.defaultTime()
    this.getDept()

  },
  mounted() {
    this.mod = localStorage.getItem('taskMode') && localStorage.getItem('taskMode') === 'Table' ? 'Table' : 'Calendar'
  }
}
</script>
